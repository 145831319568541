<template>
  <BaseCard
    :caption-visible="false"
    :footer-visible="false"
  >
    <template #title>
      <span class="h3 font-weight-bold mb-0 text-uppercase">pedidos</span>
    </template>
    <OrdersTable />
  </BaseCard>
</template>

<script>
import BaseCard from '@/components/ui/card/BaseCard.vue'
import OrdersTable from '@/components/orders/table/OrdersTable.vue'

export default {
  components: {
    OrdersTable,
    BaseCard,
  },
}
</script>

<style scoped>
</style>
